<template>
  <div class="content">
    <div class="about">
      <div class="bm_top">基金从业人员资格考试网上报名服务平台</div>
      <div class="bm_tishi">您提交信息后将会有专业老师电话指导您进行考试报名，<br>并提供相关报名服务、优惠信息，<br>根据您的需求提供相关考试培训服务，请您保持电话畅通。</div>
      <div class="bm_cont clearAfter">
        <div class="bm_infor">
          <div class="bm_prompt clearAfter">
            <p class="title">温馨提示</p>
            <ul>
              <li>
                <p class="num">1、</p>
                <p>注册时请务必输入正确的姓名和手机号码，以免影响考后成绩查询</p>
              </li>
              <li>
                <p class="num">2、</p>
                <p>基金报名相关信息：<br>
                  考试时间（预约考）：2020年10月31日<br>
                  考试报名时间：2020年9月14日至10月9日<br>
                  考试时间（统考）：2020年11月28日<br>
                  考试报名时间：2020年10月19日至11月2日</p>
              </li>
              <li>
                <p class="num">3、</p>
                <p>准考证打印时间：考前一周</p>
              </li>
            </ul>
          </div>
        </div>
        <div class="bm_form">
          <p class="title"><img src="../../assets/ren.png">2020基金从业考试报名通道</p>
          <form>
            <div class="item">
              <Input type="text" v-model="formInline.name" name="name" placeholder="请输入姓名" maxlength="20"/>
            </div>
            <div class="item">
              <Input type="text" v-model="formInline.phone" name="phone" placeholder="请输入电话" maxlength="11"/>
            </div>
            <div class="check">
              <Checkbox v-model="isAgree"> 同意《用户注册协议》，并授权使用个人信息 </Checkbox>
            </div>
            <Button class="btn-box" @click="handleSubmit">提 交</Button>
          </form>
        </div>
      </div>
      <div class="bm_tip"><img src="../../assets/laba.png">您提交信息后将会有专业老师电话指导您进行考试报名，并提供相关报名服务、优惠信息，根据您的需求提供相关考试培训服务，请您保持电话畅通</div>
    </div>
  </div>
</template>

<script>
import { getData } from '@/api/signUp'

export default {
  data () {
    return {
      formInline: {
        name: '',
        phone: ''
      },
      isAgree: true
    }
  },
  methods: {
    handleSubmit () {
      if (!this.formInline.name) {
        this.$layer.msg('考生姓名必填！')
        return false
      }
      if (!this.formInline.phone) {
        this.$layer.msg('考生电话必填！')
        return false
      }
      if (!(/^1\d{10}$/.test(this.formInline.phone))) {
        this.$layer.msg('考生电话格式不正确！')
        return false
      }
      if (!this.isAgree) {
        this.$layer.msg('请勾选同意用户注册协议！')
        return false
      }
      this.formInline.key = this.$route.query.key
      this.formInline.source = 'baidu.pc'
      getData(this.formInline)
        .then(async res => {
          if (res.status === 1) {
            this.$layer.msg('报名成功！')
            window.location.href = 'https://www.lekaoschool.com'
          } else {
            this.$layer.msg('网络错误，请稍后再试！')
          }
        })
        .catch(error => {
          this.$layer.msg('网络错误，请稍后再试！' + error)
        })
    }
  }
}
</script>

<style scoped>
ul {
  list-style: none;
}
li {
  display: list-item;
  text-align: -webkit-match-parent;
}
.content{
  width: 100%;
  height: 100vh;
  background: url("../../assets/beijing.jpg") no-repeat;
  background-size: 100% 100%;
  display: flex;
  justify-content: center;
}
.bm_top {
  padding-top: 110px;
  font-size: 42px;
  font-weight: bold;
  color: #FA535A;
}
.bm_tishi {
  margin-top: 12px;
  line-height: 40px;
  font-size: 18px;
}
.bm_cont {
  width: 1026px;
  height: 442px;
  margin: 72px auto 0 auto;
  background: #FA535A;
  box-shadow: 0px 3px 8px rgba(30, 111, 72, 0.35);
  border-radius: 10px;
  display: flex;
}
.bm_infor {
  width: 605px;
}
.bm_prompt {
  padding: 45px 0 0 45px;
  text-align: left;
  font-weight: 500;
}
.bm_prompt .title {
  line-height: 55px;
  font-size: 22px;
  color: #FFF;
}
.bm_prompt ul li {
  line-height: 34px;
  font-size: 16px;
  color: #FFF;
  border-right: 1px solid #FFF;
  display: flex;
}
.bm_form {
  width: 421px;
  margin-top: 50px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.bm_form .title {
  text-align: center;
  font-size: 22px;
  color: #FFF;
}
bm_form .title img {
  display: inline-block;
  vertical-align: middle;
  margin: -3px 7px 0 0;
}
.bm_form form {
  margin-top: 25px;
  width: 300px;
}
.bm_form form .item {
  width: 322px;
  height: 52px;
  margin-bottom: 10px;
  padding-left: 82px;
  border-radius: 8px;
  box-shadow: 0px 3px 7px rgba(0,0,0,.05);
}
.bm_form form .item:nth-child(1) {
  background: #FFF url('../../assets/xingming.png') no-repeat left 32px center;
}
.bm_form form .item:nth-child(2) {
  background: #FFF url('../../assets/shouji.png') no-repeat left 32px center;
}
/deep/ .ivu-input {
  display: block;
  width: 240px;
  height: 52px;
  font-size: 17px;
  color: #666;
  border-radius: 8px;
  border: none;
  box-shadow: none;
}
.bm_form form .check {
  width: 322px;
  margin-top: 25px;
  font-size: 12px;
  color: #FFF;
  text-align: left;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
/deep/ .bm_form form .check .input {
  display: inline-block;
  vertical-align: middle;
  width: 14px;
  height: 14px;
  margin: -1px 5px 0 0;
  border: 1px solid #DBD6D6;
  border-radius: 2px;
  -webkit-appearance: none;
}
.btn-box{
  width: 322px;
  height: 51px;
  margin-top: 25px;
  font-size: 21px;
  color: #FFF;
  background: transparent;
  border: 1px solid #FFF;
  border-radius: 8px;
}
.bm_tip {
  margin: 25px 0;
  font-size: 12px;
  color: #999;
}
.bm_tip img {
  display: inline-block;
  vertical-align: middle;
  margin: -3px 8px 0 0;
}
</style>
